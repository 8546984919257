a {
    color: #239F5E;
    text-decoration: none;
}




.Toastify__toast-container--bottom-right {
    padding: 0px !important;
    font-size: 14px !important;
}

/* progress bar */
.Toastify__progress-bar--error {
    background-color: #ED2E7E !important;
}
.Toastify__progress-bar--success {
    background-color: #239F5E !important;
}
.Toastify__progress-bar--warning {
    background-color: #F4B740 !important;
}
/* TOASTY */
.Toastify__toast--error {
    background-color: #FFF !important;
    color:#ED2E7E !important;
    padding: 7px !important;
}
.Toastify__toast--success {
    background-color: #FFF !important;
    color:#239F5E !important;
    padding: 7px !important;
}
.Toastify__toast--warning {
    background-color: #FFF !important;
    color:#F4B740 !important;
    padding: 7px !important;
}
/* close button */
.Toastify__toast--error .Toastify__close-button {
    color: #ED2E7E !important;
}
.Toastify__toast--success .Toastify__close-button {
    color: #239F5E !important;
}
.Toastify__toast--warning .Toastify__close-button {
    color: #F4B740 !important;
}



.MessageNavbar{
    overflow-y: scroll;
    padding: 20px 10px 20px 0px;
    gap: 20px;
    width: 300px;
    box-shadow: 10px 0px 20px 0px #cecece5d;
}

.MessageNavbar::-webkit-scrollbar {
    width: 0;
}
.hiddenScroll::-webkit-scrollbar {
    width: 0;
}

.MuiBackdrop-root{
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(1.5px);
}